<template>
  <div class="pb-8">
    <!-- <div class="custom-gradient text-center text-2xl not-italic font-semibold md:pb-2 sm:pb-2 md:mb-6 sm:mb-2 mt-16">
      <span>{{ $t("activity_details") }}</span>
    </div> -->
    <div v-if="!isLoading" class="flex flex-wrap justify-around sm:flex-col paymentWrapper">
      <activityCard
          :activity="activity"
          :isDetail="true"
      />
    </div>
    <Transition>
      <PopUpLight
        v-if="openPaymentPopUp"
        :showOkButton="true"
        :showClose="false"
        @closed="closePaymentMethod()"
      >
        <div>
          <div>
            <h4 class="text-center">{{ alreadySigned }}</h4>
            <h5 class="mt-4 text-center">{{ payMsg }}</h5>
            <div class="flex mt-4 justify-around">
              <BorderButton @clicked="pay" :text="$t('pay')"></BorderButton>
            </div>
          </div>
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActivityDetail from "@/components/booking/activityDetail.vue";
import { BookingStatus, Category } from "@/enum/constants";
import { customGlobalError } from "@/helpers/errorHelper";
import { ActivityHelper } from "@/helpers/activityHelper";
import PopUpLight from "@/components/popup/popupLight.vue";
import BorderButton from "@/components/buttons/borderButton.vue";
import store from "../store";
import { Format } from "@/helpers/formatHelper";
import { encodeString } from "@/helpers/dataHelper";
import { Activity } from "models";
import  activityCard  from "@/components/booking/activityCard.vue"


export default defineComponent({
  name: "ActivityJoinView",
  components: {
    //ActivityDetail,
    activityCard,
    PopUpLight,
    BorderButton,
  },
  data() {
    return {
      activity: {} as Activity,
      openPaymentPopUp: false,
      payMsg: "",
      alreadySigned: "",
      query: "",
      activityId: "",
      enabled: true,
      user: { id: 0 },
      isLoading: true,
    };
  },
  async created() {
    const user = localStorage.getItem("user");
    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
    this.user = JSON.parse(user);
    this.activityId = this.$route.query.a as string;
    if (Format.IsNull(this.activityId)) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }
    store.commit("setLoading", true);
    this.isLoading = true
    const response = await ActivityHelper.findByHash(this.activityId.toString());
    store.commit("setLoading", false);
    this.isLoading = false
    //que hacemos con una actividad que ha caducado?
    this.activity = response;
    
  },
  mounted() {
    if (window.location.href.includes("join")) {
      document.body.style.overflowY = "scroll";
    }
    document.body.style.background = "none";
  },
  methods: {
    openPaymentMethodHandler(query: any) {
      this.query = query;
      this.payMsg = this.$t("do_you_want_to_pay_question");
      this.alreadySigned = this.$t("already_signed");
      this.openPaymentPopUp = true;
    },
    closePaymentMethod() {
      this.openPaymentPopUp = false;
    },
    pay() {
      this.$router.push({
        path: "/checkout",
        query: { pl: encodeString(JSON.stringify(this.query)) },
      });
    },
  },
});
</script>

<style scoped>
.paymentWrapper {
  width: 90%;
  margin: 0 auto;
}
.paymentBoxLeft {
  border-radius: 24px;
  background: var(--background-color-box);
  /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
}
.paymentBoxLeft .custom-gradient {
  border-bottom: 1px solid #ffffff21;
}

@media (min-width: 767px) {
  .paymentBoxRight {
    width: 700px;
    height: 754px;
    background: var(--background-color-box);
    /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
    border-radius: 20px;
  }

  .paymentBoxLeft {
    width: 40%;
    min-width: 512px;
    /* height: 754px; */
  }
}
</style>