import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed54e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pb-8" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-wrap justify-around sm:flex-col paymentWrapper"
}
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "mt-4 text-center" }
const _hoisted_5 = { class: "flex mt-4 justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_activityCard = _resolveComponent("activityCard")!
  const _component_BorderButton = _resolveComponent("BorderButton")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_activityCard, {
            activity: _ctx.activity,
            isDetail: true
          }, null, 8, ["activity"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.openPaymentPopUp)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              showOkButton: true,
              showClose: false,
              onClosed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closePaymentMethod()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.alreadySigned), 1),
                    _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.payMsg), 1),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_BorderButton, {
                        onClicked: _ctx.pay,
                        text: _ctx.$t('pay')
                      }, null, 8, ["onClicked", "text"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}