import {
  Category,
  Gender,
  GenderLabel,
  MAX_LENGTH_TEXT, Purpose,
  SearchType,
  Sport,
  SportLabel
} from '@/enum/constants';
import store from "../store";
import { getTimeFromDateString } from './dateHelper';
import axios from 'axios';
import { Format } from './formatHelper';
import { MatchFilter } from '@/custom-models/matchFilter';
import { watch, computed } from 'vue';
import { useStore } from 'vuex';

export function getDataToBooking(tenant: any): any {
  const defaultDataSend = getSearchDataToSend();
  const time = getTimeFromDateString(defaultDataSend.date);

  return {
    name: "booking",
    query: {
      id: tenant.id,
      name: tenant.name,
      date: defaultDataSend.date,
      sport: defaultDataSend.sport,
      category: defaultDataSend.category,
      hour: time
    },
  };
}

export function replaceStateTenantBookingWithHistory(tenant: any) {
  const defaultDataSend = getSearchDataToSend();
  const time = getTimeFromDateString(defaultDataSend.date);

  // Crea la URL con los parámetros necesarios
  const url = `/booking?id=${tenant.id}&name=${tenant.name}&date=${defaultDataSend.date}&sport=${defaultDataSend.sport}&category=${defaultDataSend.category}&hour=${time}`;

  // configurar el estado que deseas almacenar
  const state = {
    tenantId: tenant.id,
    tenantName: tenant.name,
    sport: defaultDataSend.sport,
  };
  // Reemplaza el estado del historial en la sesión actual
  history.replaceState(state, '', url);
}

export function replaceStateLocationWithHistory(searchData: any) {
  // Construcción manual de la URL con los parámetros necesarios
  const url = `/search-results?tenantIds=${searchData.tenantIds ?? ""}`
    + `&date=${searchData.date}`
    + `&cordX=${searchData.cordX}`
    + `&cordY=${searchData.cordY}`
    + `&category=${searchData.category}`
    + `&radius=${searchData.radius}`
    + `&sport=${searchData.sport}`
    + `&searchType=${searchData.searchType}`
    + `&purpose=${searchData.purpose}`
    + `&locName=${encodeURIComponent(searchData.locName)}`;

  // Configurar el estado que deseas almacenar
  const state = {
    searchType: searchData.searchType,
    sport: searchData.sport,
    locName: searchData.locName,
  };

  // Reemplazar el estado del historial en la sesión actual
  history.replaceState(state, '', url);
}

export function getSearchDataToSend(): any {

  let cordY = null;
  let cordX = null;
  let tenantIds = null;
  let sport = Sport.PADEL;
  let searchIsFlexible = true;

  const category = store.state.preferences.action !== null ? store.state.preferences.action : Category.BOOKING;

  if (store.state.preferences.tenants != null && store.state.preferences.tenants.length !== 0) {
    cordY = store.state.preferences.tenants[0].cordY;
    cordX = store.state.preferences.tenants[0].cordX;
  }
  if (store.state.preferences.places != null && store.state.preferences.places.length !== 0) {
    cordY = store.state.preferences.places[0].cordY;
    cordX = store.state.preferences.places[0].cordX;
  }
  const userJson = localStorage.getItem("user");//solo enviamos los preferidos en el caso de que no se haya entrado coordenadas
  if (userJson !== null &&
    Format.IsNull(cordY) && 
    Format.IsNull(cordX)
  ) {
    const user = JSON.parse(userJson);
    if (user.preferences.tenants != null && user.preferences.tenants.length) {
      tenantIds = user.preferences.tenants.map((tenant: { tenantId: string; }) => tenant.tenantId);
    }
  }

  if (store.state.preferences.sport != null) {
    sport = store.state.preferences.sport;
  }
  searchIsFlexible = store.state.searchIsFlexible === "true" || store.state.searchIsFlexible === true ? true : false;
  const date = store.state.fullDateTime;
  return {
    tenantIds: tenantIds,
    date: date,
    cordX: cordX,
    cordY: cordY,
    category: category,
    //TODO: hardcoded hasta nuevo aviso
    radius: 3,
    sport: sport,
    searchType: searchIsFlexible === true ? SearchType.FLEXIBLE : SearchType.EXACT,
    purpose: Purpose.BOOKING
  };

}

export function checkURLBookingFormat(obj: any): boolean {
  //id=naccopy_34834738_taykus_68&date=2023-07-07T08:39&sport=2&category=0&hour=11:00
  if (
    "id" in obj &&
    "date" in obj &&
    "sport" in obj &&
    "category" in obj &&
    "hour" in obj
  ) {
    return true;
  }
  return false;
}

export function checkURLAvailabilityFormat(obj: any): boolean {//tenantIds es excluyente y CordX y CordY tambien asi que no deben verificarse
  //?tenantIds=1&tenantIds=2&date=2023-07-05T09:33&cordX=40.4167754&cordY=-3.7037902&category=0&radius=3&sport=2&flexibleTime=true
  if (
    "date" in obj &&
    "category" in obj &&
    "radius" in obj &&
    "sport" in obj
  ) {
    return true;
  }
  return false;
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function generateAndDownloadFile(data: string) {
  try {
    const mappedData = data;

    // Convert the mapped data to a JSON string
    const jsonData = JSON.stringify(mappedData);

    // Create a Blob object containing the JSON data
    const blob = new Blob([jsonData], { type: 'application/json' });

    // Create a temporary URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'mappedData.json'; // Set the desired file name

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error fetching and generating data:', error);
  }
}

export function encodeString(msg: string) {
  const utf8Booking = encodeURIComponent(msg);
  return btoa(utf8Booking);
}

export function decodeString(msg: string) {
  const strQuery = atob(msg);
  return decodeURIComponent(strQuery);
}

interface CountryData {
  cca2?: string;
  name?: {
    common?: string;
  };
  flags?: {
    png?: string;
  };
  idd?: {
    root?: string;
    suffixes?: string[];
  };
}

export async function getCountryData() {
  const response = await axios.get<CountryData[]>('https://restcountries.com/v3.1/all');
  const countries: { [key: string]: { code: string, name: string, flag: string, prefix: string; }; } = {};

  // response.data.forEach((countryData: CountryData) => {
  //   if (countryData !== undefined) {
  //     countries[countryData.cca2 || ''] = {
  //       code: countryData.cca2 || '',
  //       name: countryData.name.common || '',
  //       flag: countryData.flags.png || '',
  //       prefix: countryData.idd.root + (countryData.idd.suffixes[0] || ''),
  //     };
  //   }
  // });

  return countries;
}

export function clearURL() {
  const url = new URL(window.location.href);
  url.search = "";
  window.history.replaceState({}, document.title, url.href);
}

export function getSportLabel(sport: number) {
  switch (sport) {
    case Sport.PADEL:
      return SportLabel.PADEL;
    case Sport.TENIS:
      return SportLabel.TENIS;
    case Sport.PICKLEBALL:
      return SportLabel.PICKLEBALL;
    case Sport.SOCCER:
      return SportLabel.SOCCER;
    case Sport.PADBOL:
      return SportLabel.PADBOL;
    case Sport.RACQUETBALL:
      return SportLabel.RACQUETBALL;
    case Sport.SQUASH:
      return SportLabel.SQUASH;
    default:
      return "Unknown Sport";
  }
}

export function addThreeDotsText(text: string) {
  if (text.length > MAX_LENGTH_TEXT) {
    return text.slice(0, MAX_LENGTH_TEXT) + "...";
  }
  return text;
}

export function addThreeDotsTextWithLength(text: string, length: number) {
  if (text.length > length) {
    return text.slice(0, length) + "...";
  }
  return text;
}

export function removeCommaStart(text: string) {
  if (text.startsWith(',')) {
    text = text.substring(1);
  }
  return text;
}

export function getGender(genderCode: number) : string{
  switch (genderCode) {
    case Gender.FEMALE:
      return GenderLabel.FEMALE;
     case Gender.MALE:
      return GenderLabel.MALE;
     case Gender.MIX:
      return GenderLabel.MIX;
    case Gender.OPEN:
      return GenderLabel.OPEN;
  }
  return "";
}

export function mapFilterAttributes(sport: number, filter: any) : MatchFilter{
  let mappedAttributes: MatchFilter = {
    type: 0,
    size: 0,
  };

  switch (sport) {
    case Sport.PADEL:
    case Sport.TENIS:
    case Sport.PICKLEBALL:
      mappedAttributes = {
        type: filter.type,
        size: filter.size,
        wall: filter.subType,
      };
      break;
    case Sport.SOCCER:
      mappedAttributes = {
        type: filter.type,
        size: filter.size,
        surface: filter.subType,
      };
    break;
  }
  return mappedAttributes;
}

export async function useRefreshWatcher(refreshCallback: () => void) {//refreshCallback pass the function that we want to execute, in this case, it will be the refresh function of the component itself so that it can refresh automatically
  const store = useStore();
  const isRefreshed = computed(() => store.state.isRefreshed);
  watch(isRefreshed, (newValue) => {
    if (newValue) {
      // Reset estatus after captured
      store.dispatch('resetRefresh');
      // Execute refresh function
      refreshCallback();
    }
  });
}

export function encodeBase64(str: string): string {
  // Codifica el string a un Uint8Array usando UTF-8
  const encoder = new TextEncoder();
  const utf8Bytes = encoder.encode(str);
  
  // Convierte cada byte a un caracter y concatena para formar una cadena "binaria"
  let binaryStr = "";
  for (let i = 0; i < utf8Bytes.length; i++) {
    binaryStr += String.fromCharCode(utf8Bytes[i]);
  }
  
  // Codifica la cadena binaria a Base64
  return btoa(binaryStr);
}

export function decodeBase64(encoded: string): string {
  // Decodifica la cadena Base64 a una cadena binaria (Latin1)
  const binaryStr = atob(encoded);
  
  // Crea un Uint8Array a partir de la cadena binaria
  const len = binaryStr.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryStr.charCodeAt(i);
  }
  
  // Decodifica el Uint8Array a una cadena UTF-8
  const decoder = new TextDecoder();
  return decoder.decode(bytes);
}