<template>
  <div>
    <MyClubs/>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import MyClubs from "@/components/myProfile/myClubs.vue";

export default defineComponent({
  name: 'menuProfileClubs',
  components: {
    MyClubs,
  },
});
</script>  