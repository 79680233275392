<template>
  <div>
    <MyPays/>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import MyPays from "@/components/myProfile/myPays.vue";
  
export default defineComponent({
  name: 'menuProfilePays',
  components: {
    MyPays,
  },
});
</script>