<template>
  <div class="background">
    <component :is="selectedLayout" />
    <Login @refresh="refreshed"/>
    <CookiesPrivacy />
    <GlobalError />
    <Loader />
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from 'vue-router';  // Usamos `useRoute` para obtener la ruta
import CookiesPrivacy from "@/components/cookies/cookiesPrivacy.vue";
import Loader from "@/components/loader/loader.vue";
import GlobalError from "@/components/globalError/GlobalError.vue";
import Login from "@/components/login/login.vue";
import { useStore } from 'vuex';  // Importar useStore

// Importar los layouts
import BaseLayout from '@/layouts/baseLayout.vue'; 
import ProfileLayout from '@/layouts/profileLayout.vue';

// Tipar los layouts
type Layouts = {
  base: typeof BaseLayout;
  profile: typeof ProfileLayout;
};

export default defineComponent({
  name: "App",
  components: {
    CookiesPrivacy,
    Loader,
    GlobalError,
    Login
  },
  setup() {
    const route = useRoute();  // Obtener la ruta actual
    const store = useStore();  // Obtener el store de Vuex

    // Definir los layouts
    const layouts = {
      base: BaseLayout,
      profile: ProfileLayout,
    } as Layouts;

    // Computed para seleccionar el layout correcto
    const selectedLayout = computed(() => {
      const layoutName = route.meta.layout as 'base' | 'profile';  // Usar `route` para obtener la ruta actual
      return layouts[layoutName] || layouts.base;  // Si no tiene layout, usamos el base
    });

    // Función para refrescar
    const refreshed = () => {
      store.dispatch('refresh');  // Llamar la acción 'refresh' de Vuex
    };

    return {
      selectedLayout,
      refreshed
    };
  },
});
</script>