import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6dcae10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "mt-12 flex justify-center ContainerButtonSearch" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "mt-12 br16 p-4 overflow-y-auto fix-height"
}
const _hoisted_5 = {
  key: 1,
  class: "flex items-center justify-center mt-12 br16 altBackground p-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchAvailability = _resolveComponent("MatchAvailability")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("my_matches")), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["sm:text-sm cursor-pointer walletButtonDisabled mr-2 WrapperButtonSearch", { 'walletButton': _ctx.nextOpen }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextClicked(true)))
        }, _toDisplayString(_ctx.$t("upcoming_matches")), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["sm:text-sm cursor-pointer walletButtonDisabled WrapperButtonSearch", { 'walletButton': !_ctx.nextOpen }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextClicked(false)))
        }, _toDisplayString(_ctx.$t("past_matches")), 3)
      ])
    ]),
    (_ctx.result && _ctx.result.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("ul", null, [
            _createVNode(_component_MatchAvailability, {
              matches: _ctx.result,
              fromRouteName: "view",
              onMatchSelected: _ctx.handleMatchSelected
            }, null, 8, ["matches", "onMatchSelected"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("dont_have_any_match")), 1)
        ]))
  ]))
}