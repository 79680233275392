<template>
  <div>
    <MyMatches/>
  </div>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';
import MyMatches from "@/components/myProfile/myMatches.vue";

export default defineComponent({
  name: 'menuProfileMatches',
  components: {
    MyMatches,
  },
});
</script>

  