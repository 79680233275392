<template>
  <div>
    <div class="flex items-center justify-center"><p>{{ $t("my_matches") }}</p></div>
    <div class="mt-12 flex justify-center ContainerButtonSearch">
      <div class="flex">
          <div
          class="sm:text-sm cursor-pointer walletButtonDisabled mr-2 WrapperButtonSearch"
          @click="nextClicked(true)"
          :class="{ 'walletButton': nextOpen }"
        >
          {{ $t("upcoming_matches") }}
        </div>
        <div
          class="sm:text-sm cursor-pointer walletButtonDisabled WrapperButtonSearch"
          @click="nextClicked(false)"
          :class="{ 'walletButton': !nextOpen }"
        >
          {{ $t("past_matches") }}
        </div>
      </div>
    </div>
    <div v-if="result && result.length > 0" class="mt-12 br16 p-4 overflow-y-auto fix-height">
      <ul>
        <MatchAvailability
          :matches="result"
          fromRouteName="view"
          @matchSelected="handleMatchSelected"
        />
      </ul>
      <!--
      <ListPagination
        :start = "start"
        :size = "size"
        @getData = "handleGetData"
      />
      -->
    </div>
    <div v-else class="flex items-center justify-center mt-12 br16 altBackground p-4">
      <p>{{ $t("dont_have_any_match") }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BookingHelper } from "@/helpers/bookingHelper";
import { Booking } from "models"
import ListPagination from "@/components/form/listPagination.vue";
import MatchAvailability from "@/components/search/matchAvailability.vue";
import store from '@/store';
import { availabilityInclude, BookingStatus } from '@/enum/constants';
import { useRefreshWatcher } from "@/helpers/dataHelper";

export default defineComponent({
    name: "MyMatches",
    data() {
      return {
        start: 0,
        size: 50,
        result: [] as Booking[],
        nextOpen: true,
        include: availabilityInclude.NEXT
      };
    },
    components: {
      MatchAvailability
    },
    async mounted() {
      useRefreshWatcher(() => { this.loadData(this.start, this.size, this.include); });
      this.loadData(this.start, this.size, this.include);
    }, 
    methods: {
      nextClicked(option: boolean){
        this.nextOpen = option;
        this.include = availabilityInclude.OLD;
        if(this.nextOpen){
          this.include = availabilityInclude.NEXT;
        }
        this.loadData(this.start, this.size, this.include);
      },
      async handleGetData(start:number, size:number, include:string){
        this.loadData(start, size, include);
      },
      async loadData(start:number, size:number, include:string){
        store.commit("setLoading", true);
        this.result = await BookingHelper.getCustomersBooking(start, size, false, include);
        store.commit("setLoading", false);
      },
      handleMatchSelected(match: Booking){
        if (match.status!.code == BookingStatus.FINISHED) {
          return this.$router.push("/booking-results/" + match.id);
        }
        return this.$router.push(`/booking-join?b=${match.id}`);
      },
    }
});
</script>
<style scoped>
.fix-height {
  max-height: calc(100vh - 200px);
}
.WrapperButtonSearch {
    padding: 2px 50px;
}
.linearSpace {
  background: linear-gradient(
    270deg,
    #023b70 3.65%,
    #406297 19.27%,
    #4668a0 83.33%,
    #033768 100%
  );
  height: 1px;
}
.altBackground {
  box-shadow: 0px 0px 15px -4px #3c7eeb;
}
.containerImageMatch {
  width: 44px;
  border-radius: 50%;
  overflow: hidden;
}
.divisor {
  width: 3px;
}
.end {
  justify-content: end;
}
.usersScore {
  position: absolute;
  right: 10px;
  top: 5px;
}
.scoreUsers {
  top: -5px;
  font-size: 10px;
  right: -10px;
  font-weight: bold;
  color: black;
}
.numberScore {
  top: 3px;
  left: 4px;
}

/* Override for a working scroll*/
@media (min-width: 1100px) {
  body {
    overflow: auto !important;
  }
}
</style>

