<!-- MainView.vue -->
<template>
  <div>
    <MyPrivacy
      @refresh="handleRefreshPrivacy"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MyPrivacy from "@/components/myProfile/myPrivacy.vue";
import CustomerHelper from "@/helpers/customerHelper";

export default defineComponent({
  name: 'menuProfilePrivacy',
  components: {
    MyPrivacy,
  },
  methods: {
    async handleRefreshPrivacy(privacy: any) {
      CustomerHelper.updateStoragePrivacy(privacy);
    },
  }
});
</script>