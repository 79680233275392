<template>
  <div>
      <div v-if="!isHome && !isIOSWebView" class="fixed top-16 left-[10%] z-[100]">
        <HistoryBack/>
      </div>
  </div>
  <Header v-if="!isWebView"></Header>
  <div>
      <slot />
  </div>
</template>
<script lang="ts">
import HistoryBack from "@/components/form/historyBack.vue";
import Header from "@/components/header/header.vue";
import { AppsHelper } from "@/helpers/appsHelper";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { useRouter } from "vue-router";

export default {
    components: {
      Header,
      HistoryBack
    },
    computed: {
      isHome(this: { $route: RouteLocationNormalizedLoaded }): boolean {
        return this.$route.path === "/";
      },
      isWebView(): boolean {
        return AppsHelper.isWebView();
      },
      isIOSWebView(): boolean {
        return AppsHelper.isIOSWebView();
      },
    }
};
</script>