<template>
  <div><!-- mt-12 sm:mt-4 -->
    <div>
      <div class="flex justify-center sm:block sm:w-full">
        <div class="sm:w-full" :class="!isPopup ? 'w-3/5' : 'w-full'">
          <div class="relative w-full input-component">
            <SvgContainer name="homeCalendarIcon" />
            <input
              ref="inputdate"
              type="text"
              class="w-full input-rounded"
              :placeholder="$t('date_and_time')"
              @click="openDatePickerModal"
              v-model="fakeDatePickerFormatted"
              :disabled="showPicker"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-4 sm:mt-4 sm:block sm:w-full">
        <div class="sm:w-full" :class="!isPopup ? 'w-3/5' : 'w-full'">
          <div v-if="!createInClub" class="relative w-full input-component">
            <InputWhere
              :placeholder="$t('search_your_clubs_cities')"
              :addLocations="true"
              :onlyOneSelect="true"
              :allowNearMe="allowNearMe"
              :searchTenantEnabled="searchTenantEnabled"
              :externalSearchQuery="searchQuery"
              @tenantsLocationsSelected="handleLocations"
              @sendNearSearch="handleSendNearToMe"
              @sendFavoritesSearch="handleSendFavorites"
              @sendTenantToBooking="handleTenantToBooking"
              :key="autocompleteKey"
              ref="inputWhere"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showSearchButton"
        class="mr-auto ml-auto flex justify-center w-3/5 mt-10 sm:mt-4 sm:block sm:w-full"
      >
        <div class="w-full">
          <div class="relative w-full input-component sm:justify-center">
            <input
              type="button"
              @click="sendData"
              class="search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12"
              :value="$t('search')"
              :disabled="hasErrors"
              :class="{ 'button-disabled': hasErrors }"
            />
          </div>
        </div>
      </div>
    </div>
    <Transition>
      <PopUpLight v-if="showPicker" @closed="this.closeDatePickerModal()">
        <div class="text-center">
          <div class="justify-center mt-4 sm:mt-4 sm:block sm:w-full">
            <InputDateTimePicker
              :hiddenInput="true"
              :initialDate="date"
              :initialTime="time"
              :initialFlexible="flexible"
              :createInClub="createInClub"
              @selectionChanged="handleDateTimeSelected"
              @close="handleClose"
            />
          </div>
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>
  
<script >
import SvgContainer from "@/views/SvgContainer.vue";
import InputWhere from "@/components/form/inputWhereSearch.vue";
import InputDateTimePicker from "@/components/form/inputDateTimePicker.vue";
import { USE_BUTTON_TO_SEND_TENANT } from "@/enum/constants"

import { useStore } from "vuex";
import {
  formatDate,
  formatTime,
  setTimeToDate,
  getNextUserHourAndDay,
  advance30min,
  beautifyDate,
  beautifyDateWithOutTime
} from "@/helpers/dateHelper";
import { Format } from "@/helpers/formatHelper";
import PopUpLight from "@/components/popup/popupLight.vue";

export default {
  name: "MainSearchForm",
  emits: ["closePopup", "locationSelected", "tenantSelected"],
  components: {
    SvgContainer,
    InputWhere,
    InputDateTimePicker,
    PopUpLight,
  },
  props: {
    isPopup: {
      type: Boolean,
      required: false,
    },
    allowNearMe: {
      type: Boolean,
      required: false,
      default: true,
    },
    showSearchButton: { 
      type: Boolean, 
      required: false, 
      default: true 
    },
    searchTenantEnabled: { 
      type: Boolean, 
      required: false, 
      default: true 
    },
    createInClub:{
      type: Boolean, 
      required: false, 
      default: false 
    },
    searchQuery:{
      type: String, 
      required: false, 
    }
  },
  async created() {
    this.store = useStore();
  },
  data() {
    return {
      isLoading: false,
      autocompleteKey: 0,
      fakeDatePicker: "",
      fakeDatePickerFormatted: "",
      showPicker: false,
      selectedLocations: null,
      date: "",
      time: "",
      flexible: true,
      sendTenant: null
    };
  },
  mounted() {
    this.setInitialDate();
    this.setInitialHour();
    this.setInitialLocations();
    this.fakeDatePicker = setTimeToDate(this.date, this.time);
    if(this.createInClub){
      this.fakeDatePickerFormatted = beautifyDateWithOutTime(this.fakeDatePicker, false, false);
      return;
    }
    this.fakeDatePickerFormatted = beautifyDate(this.fakeDatePicker, false, false);
  },
  computed: {
    isSelectedLocationValid() {
      if (
        this.selectedLocations === null || 
        this.selectedLocations.length <= 0
      ) {
        return false;
      }
      return true;
    },
    hasErrors() {
      return !this.isSelectedLocationValid;
    },
  },
  methods: {
    setInitialHour() {
      const now = new Date();
      const advancedDate = advance30min(now);

      //Query url date
      if (
        this.$route.query.date != null ||
        this.$route.query.date != undefined
      ) {
        let queryDate = new Date(this.$route.query.date);
        if (queryDate < now) {
          queryDate = advancedDate;
        }
        this.time = formatTime(queryDate, navigator);
        return;
      }

      // //Preferences date
      let user = null;
      if (localStorage.getItem("user") != null) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      if (user != null) {
        if (
          user.preferences != null &&
          user.preferences.times != null &&
          user.preferences.times.length != 0
        ) {
          const userDayTime = getNextUserHourAndDay(user.preferences.times);
          this.time = userDayTime.userTime;
          return;
        }
      }

      const currentHour = advancedDate.getHours();
      const currentMinute = Math.floor(advancedDate.getMinutes() / 30) * 30;
      advancedDate.setHours(currentHour);
      advancedDate.setMinutes(currentMinute);
      this.time = formatTime(advancedDate, navigator);
    },
    clearSearch(){
      this.$refs.inputWhere.clearSearch();
    },
    setInitialLocations() {
      if(this.createInClub){
        this.selectedLocations = [
          { name: '', tenantId: this.$route.query.tenantIds },
        ];
        return;
      }
      if (!Format.IsNull(this.$route.query.id) && !Format.IsNull(this.$route.query.name)) {
        this.selectedLocations = [
          { name: this.$route.query.name, tenantId: this.$route.query.id },
        ];
      }

      if (!Format.IsNull(this.$route.query.cordX) && !Format.IsNull(this.$route.query.cordY)) {
        this.selectedLocations = [
          {
            name: this.$route.query.locName,
            cordX: this.$route.query.cordX,
            cordY: this.$route.query.cordY,
          },
        ];
      }
    },
    setInitialDate() {
      const now = new Date();
      const advancedDate = advance30min(now);
      let urlDate = null;
      if (this.$route.query.date != null || this.$route.query.date != undefined
      ) {
        urlDate = new Date(this.$route.query.date);
        if (urlDate < now) {
          this.date = advance30min(now);
          return;
        }
        this.date = urlDate;
        return;
      }

      const userItem = localStorage.getItem("user");
      let defaultDate = null;
      if (userItem !== null) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.preferences != null && user.preferences.times != null && user.preferences.times.length != 0
        ) {
          const userDayTime = getNextUserHourAndDay(user.preferences.times);
          defaultDate = new Date();
          defaultDate.setDate(
            defaultDate.getDate() +
              ((userDayTime.day - defaultDate.getDay() + 7) % 7)
          ); //revisar el %7 por si no queremos que busque los dias anterior -> "Calcula la cantidad de días que se deben sumar a la fecha actual para llegar al día deseado (en este caso, 4). La expresión (day - currentDate.getDay() + 7) % 7 asegura que se agregue la cantidad correcta de días, incluso si el día deseado es anterior al día actual de la semana."
          defaultDate.setHours(userDayTime.time, 0, 0, 0);

          this.date = defaultDate;
          return;
        }
      }
      this.date = advancedDate;
    },
    openDatePickerModal() {
      this.showPicker = true;
    },
    handleLocations(locations) {
      this.selectedLocations = locations;
      if (this.showSearchButton === false) {
        this.sendData();
      }
    },
    handleSendNearToMe(locations, waitForButton) {
      this.selectedLocations = locations;
      if(!waitForButton){
        this.sendData();
      }
    },
    handleSendFavorites(locations, waitForButton) {
      this.selectedLocations = locations;
      if(!waitForButton){
        this.sendData();
      }
    },
    handleDateTimeSelected(selection) {
      this.date = selection.date;
      this.time = formatTime(selection.date, navigator);
      if(this.sendTenant){
        this.sendTenant.date = formatDate(this.date)
        this.sendTenant.time = this.time;
      }
      this.flexible = selection.flexible;
      if(this.createInClub){
        this.fakeDatePickerFormatted = beautifyDateWithOutTime(selection.date, false, false);
        return;
      }
      this.fakeDatePickerFormatted = beautifyDate(selection.date, true, false);
    },
    handleClose(){
      this.showPicker = false;
    },
    handleTenantToBooking(tenant, waitForButton) {
      this.store.commit("setTenantInfo", tenant);
      if (this.date === "") {
        this.setInitialDate();
      }
      this.selectedLocations = USE_BUTTON_TO_SEND_TENANT;
      this.sendTenant = {
        tenant: tenant,
        date: formatDate(this.date),
        time: this.time,
        flexible: this.flexible,
      }
      if(!waitForButton){
        this.$emit("tenantSelected", this.sendTenant);
      }
    },

    closeDatePickerModal() {
      this.showPicker = false;
    },
    sendData() {
      if(this.selectedLocations === USE_BUTTON_TO_SEND_TENANT){
        return this.$emit("tenantSelected", this.sendTenant);
      }
      if (this.isPopup) {
        this.$emit("closePopup");
      }

      if (this.date === "") {
        this.setInitialDate();
      }
      this.$emit("locationSelected", {
        date: formatDate(this.date),
        flexible: this.flexible,
        location: this.selectedLocations,
      });
    },
  },
};
</script>
<style>
input[type="button"],
input[type="submit"] {
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  padding: 14.5px 0;
  cursor: pointer;
  width: 100%;
}
.header-modal button {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
  