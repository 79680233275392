<template>
  <div>
    <MyLikes/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MyLikes from "@/components/myProfile/myLikes.vue";

export default defineComponent({
  name: 'menuProfileLikes',
  components: {
    MyLikes,
  },
});
</script>