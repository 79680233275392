import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-16 left-[10%] z-[100]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryBack = _resolveComponent("HistoryBack")!
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (!$options.isHome && !$options.isIOSWebView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_HistoryBack)
          ]))
        : _createCommentVNode("", true)
    ]),
    (!$options.isWebView)
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}