<template>
    <div>
        <div class="fixed top-16 left-[10%] z-[100]">
            <HistoryBack
            :disableAction="true" 
            @backParent="goBack"
            />
        </div>
    </div>
    <div class="background-app">
        <slot />
    </div>
</template>
<script lang="ts">
import HistoryBack from "@/components/form/historyBack.vue";
import { useRouter } from "vue-router";

export default {
    components: {
        HistoryBack,
    },
    methods: {
        goBack(this: { $router: ReturnType<typeof useRouter> }) {
            if (this.$router.currentRoute.value.path === "/menu-profile") {
                this.$router.push("/");
            } else {
                this.$router.push("/menu-profile");
            }
        },
    },
};
</script>