<template>
  <div class="search-elements flex flex-wrap items-center justify-center m-auto">
    <div class="product-search-wrapper relative w-3/12">
      <div
        class="image-search m-auto cursor-pointer"
        @click="goToTenant($event)"
      >
        <img class="m-auto" :src="tenantImage" :alt="name" />
        <div>
          <ILikeButton class="ilike-icon-absolute"
            :tenantId="tenantId"
          />
        </div>
        <div
          v-if="private === true"
          class="absolute lock-icon fill-white p-2 cursor-pointer"
        >
          <SvgContainer name="lockIcon" />
        </div>
      </div>
      <div class="-mt-20 container-blur-text p-5 pb-0">
        <div class="flex justify-between">
          <span>{{ capitalizedName }}</span>
          <span v-html="parsedBestPrice"></span>
        </div>
        <div class="flex justify-content mt-2 mb-2 items-center">
          <div class="flex search-btn" @click="openGoogleMaps($event)">
            <p class="pl-2 pr-2">{{ $t("show_on_map") }}</p>
          </div>
          <div v-if="distance" class="items-center ml-4">
            <span>{{ distance }}</span>
          </div>
        </div>
        <CustomSlider v-if="hours && hours.length > 0"
          :hours="hours"
          :tenantId="tenantId"
          @hourSelected="hourSelected"
         />
         <div v-else>
          <div class="pt-4 pb-4">
            {{ $t("search_no_resources_found") }}
          </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSlider from "./customSlider.vue";
import SvgContainer from "../../views/SvgContainer.vue";
import { Format } from "@/helpers/formatHelper";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";
import ILikeButton from "@/components/booking/iLikeButton"

export default {
  name: "availabilityElement",
  components: {
    CustomSlider,
    SvgContainer,
    ILikeButton
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    bestPrice: {
      type: String,
      required: false,
    },
    location: {
      type: String,
      required: false,
    },
    hours: {
      type: Array,
      required: false,
    },
    distance: {
      type: String,
      required: false,
      default: null
    },
    gallery: {
      type: String,
      required: false,
    },
    tenantId: {
      type: String,
      required: true,
    },
    cordX: {
      type: Number,
      required: true,
    },
    cordY: {
      type: Number,
      required: true,
    },
    private: {
      type: Boolean,
      required: true,
    },
    showTax: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ["hourSelected", "tenantSelected"],
  methods: {
    openGoogleMaps(e) {
      e.preventDefault();
      e.stopPropagation();
      window.open(
        `https://www.google.com/maps/place/${this.cordX},${this.cordY}`
      );
    },
    hourSelected(hour) {
      this.$emit("hourSelected", {
        tenantId: this.tenantId,
        hour: hour,
        name: this.name,
        private: this.private
      });
    },
    goToTenant(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("tenantSelected", {
        tenantId: this.tenantId,
        name: this.name,
      });
    },
  },
  data() {
    return {
      tenantImage: null,
      user: null,
      favoriteTenants: null,
      capitalizedName: capitalizeFirstLetter(this.name),
      parsedBestPrice: this.bestPrice
    };
  },
  mounted() {
    if(this.showTax){
      this.parsedBestPrice = Format.addTaxString(this.parsedBestPrice);
    }
    this.tenantImage = "@/assets/images/other-image.png";
    if (this.$props.gallery != null || this.$props.gallery != "") {
      let imagesArray = this.$props.gallery.split(" ");
      if (imagesArray.length > 0) {
        this.tenantImage = imagesArray[0];
      }
    }
  },
};
</script>

<style scoped>
.ilike-icon-absolute{
  position: absolute;
  top: 14px;
  right: 30px;
}
.search-btn {
  backdrop-filter: initial;
  background: #03437f;
  align-items: center;
  min-height: 21px;
  font-size: 12px;
  cursor: pointer;
}
.container-blur-text {
  width: 304px;
  background: linear-gradient(
      180deg,
      rgba(32, 60, 109, 0.5) 0%,
      rgba(28, 43, 69, 0.095) 100%
    ),
    linear-gradient(
      180deg,
      rgba(32, 60, 109, 0.5) 0%,
      rgba(28, 43, 69, 0.095) 100%
    );
  backdrop-filter: blur(4px);
  border-radius: 16px;
  font-size: 14px;
}
.container-hours {
  gap: 10px;
}
.container-hours div {
  background: #2b4762;
  border-radius: 16px;
  padding: 2px 8px;
}
.lock-icon {
  top: 14px;
  border-radius: 50%;
  background: rgba(20, 40, 74, 0.2);
  backdrop-filter: blur(4px);
  margin-left: 12px;
}
.search-elements {
  max-width: 1340px;
  gap: 16px;
  margin: 0px 0px 20px 0px;
  overflow-y: initial;
}
.search-reference {
  width: 90%;
  margin: 0 auto;
}
.product-search-wrapper {
  width: fit-content;
  overflow-y: initial;
}
.search-reference .image-search {
  width: 272px;
  height: 184px;
  overflow: hidden;
}

.search-reference .image-search img {
  border-radius: 20px;
}
.search-reference li {
  max-height: 42px;
  margin-top: 6px;
}
.search-image {
  background: linear-gradient(
    265.36deg,
    #0356e1 0.37%,
    #23edee 136.83%
  ) !important;
}
</style>
