<template >
  <Transition>
    <Popup
      class="reference-onboarding"
      v-if="openOnboarding || $store.state.onboardingIsOpen"
      :showOkButton="false"
    >
      <!-- header -->
      <div class="relative w-full">
        <!--<img class="absolute w-full" :src="clubData.secondaryLogo || '../../assets/images/TAYKUS.svg'"  alt="">-->
        <img
          class="absolute w-full"
          src="../../assets/images/TAYKUS.svg"
          alt=""
        />
      </div>
      <header class="flex justify-between relative h-24">
        <div v-if="selectedValue > 0" 
          @click="moveIndicator(-1)"
          class="cursor-pointer back-button h-8 w-8 flex justify-center items-center"
        >
          <img src="../../assets/images/arrow-prev.svg" alt="" />
        </div>
        <div class="cursor-pointer ml-auto" @click="closeOnboarding()">
          {{ $t("skip") }}
        </div>
        <div class="slide-position flex h-16">
          <div
            v-for="(indicator, index) in indicators"
            :key="index"
            :class="[
              'indicator h-1 w-full',
              { 'select-indicator': index  === selectedValue },
              { 'select-indicator': index + 1  === selectedValue },
              { 'select-indicator': index + 2 === selectedValue },
              { 'select-indicator': index + 3  === selectedValue },
            ]"
          ></div>
        </div>
        
      </header>
      <div class="w-120">
        <UsuallyGame v-if="selectedValue == 0"  />
        <UsuallyPlay v-if="selectedValue == 1" @showNextStep="moveIndicator"  />
        <PlayDay v-if="selectedValue == 2"  />
        <UsuallyDo v-if="selectedValue == 3"  />
      </div>
      <!-- footer -->
      <div v-if="selectedValue == 0" class="next-button absolute w-full cursor-pointer">
        <div class="relative">
          <img
            class="button-next-img absolute"
            src="../../assets/images/arrow-prev.svg"
            alt=""
          />
          <img
            class="button-next-b absolute"
            src="../../assets/images/border-button1.svg"
            alt=""
            @click="moveIndicator(1)"
          />
        </div>
      </div>
      <div v-if="selectedValue == 0" class="next-button absolute w-full cursor-pointer">
        <div class="relative">
          <img
            class="button-next-img absolute"
            src="../../assets/images/arrow-prev.svg"
            alt=""
          />
          <img
            class="button-next-b absolute"
            src="../../assets/images/border-button1.svg"
            alt=""
            @click="moveIndicator(1)"
          />
        </div>
      </div>
      <div v-if="selectedValue == 1" class="next-button absolute w-full cursor-pointer">
        <div class="relative">
          <img
            class="button-next-img absolute"
            src="../../assets/images/arrow-prev.svg"
            alt=""
          />
          <img
            class="button-next-b absolute"
            src="../../assets/images/border-button.svg"
            alt=""
            @click="moveIndicator(1)"
          />
        </div>
      </div>
      <div v-if="selectedValue == 2" class="next-button absolute w-full cursor-pointer">
        <div class="relative">
          <img
            class="button-next-img absolute"
            src="../../assets/images/arrow-prev.svg"
            alt=""
          />
          <img
            class="button-next-b absolute"
            src="../../assets/images/border-3.svg"
            alt=""
            @click="moveIndicator(1)"
          />
        </div>
      </div>
      <div v-if="selectedValue == 3" class="next-button absolute w-full cursor-pointer">
        <div class="relative">
          <img
            class="button-next-img absolute"
            src="../../assets/images/arrow-prev.svg"
            alt=""
          />
          <img
            class="button-next-b absolute"
            src="../../assets/images/border-button4.svg"
            alt=""
            @click="moveIndicator(1)"
          />
        </div>
      </div>
    </Popup>
  </Transition>
</template>
  
<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/popup/popup.vue";
import store from "../../store";
import { CustomerPreferences } from "../../../models";
import { CustomerApi } from "../../service/CustomerApi";
import UsuallyGame from "@/components/onboarding/usuallyGame.vue";
import UsuallyPlay from "@/components/onboarding/usuallyPlay.vue";
import PlayDay from "@/components/onboarding/playDay.vue";
import UsuallyDo from "@/components/onboarding/usuallyDo.vue";

export default defineComponent({
  name: "OnboardingPopUp",
  emits: ["refresh"],
  components: {
    Popup,
    UsuallyGame,
    UsuallyPlay,
    PlayDay,
    UsuallyDo,
  },
  props: {
    open: { type: Boolean, default: false },
    returnUrl: { type: String },
  },
  data() {
    return {
      openOnboarding: false,
      error: "",
      showModal: false,
      selectedValue: 0,
      indicators: [0, 1, 2, 3], // Add more indicators if needed
      preferencesData: {} as CustomerPreferences,
    };
  },
  mounted() {
    this.openOnboarding = this.open;
    this.error = "";
  },
  methods: {
    closeOnboarding() {
      this.error = "";
      this.openOnboarding = false;
      store.commit("closeOnboarding");
      this.refreshParent();
    },
    refreshParent() {
      this.$emit("refresh");
      this.checkReturnUrl();
    },
    checkReturnUrl(){
      const urlRedirect = JSON.parse(localStorage.getItem('localhost:4000_url_redirect')!);
      
      if (urlRedirect && urlRedirect !== '') {
        window.location.href = urlRedirect.value;
      }

    },
    moveIndicator(direction: any) {
      const newIndex = this.selectedValue + direction;
      if (newIndex == this.indicators.length) {
        this.preferencesData.sport = store.state.preferences.sport;
        this.preferencesData.action = store.state.preferences.action;
        this.preferencesData.places = store.state.preferences.places;
        this.preferencesData.tenants = store.state.preferences.tenants;
        const days = store.state.preferences.days.map(
          (time: boolean[], index: number) => {
            return {
              day: index,
              morning: time[0],
              noon: time[1],
              afternoon: time[2],
            };
          }
        );
        this.preferencesData.days = days;

        this.saveData(this.preferencesData);
      }
      if (newIndex >= 0 && newIndex < this.indicators.length) {
        this.selectedValue = newIndex;
      }
    },
    async saveData(customerPreferences: CustomerPreferences) {
      await CustomerApi.updatePreferences(customerPreferences);
      this.closeOnboarding();
    },
  },
});
</script>

<style>
.reference-onboarding .play-wrapper {
  gap: 12px 24px;
  padding: 12px 0;
}

.reference-onboarding .play-wrapper div svg {
  left: 404px;
  top: 414px;
  background: rgba(255, 255, 255, 0.02);
  box-shadow: inset 0px 0px 9.85714px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(13.1429px);
  border-radius: 19.7143px;
}

.reference-onboarding .taykus-logo {
  padding: 0 50px 0 16px;
}

.reference-onboarding .close {
  display: none;
}

.reference-onboarding header {
  padding: 0 8px;
  height: 120px;
  flex-wrap: wrap;
}

.reference-onboarding .back-button {
  border-radius: 50%;
  padding: 10px 10px 8px 10px;
  background-color: #2B4164;
}

.reference-onboarding .slide-position {
  align-items: end;
  padding: 0 24px;
  gap: 8px;
  width: 100%;
}

.reference-onboarding .indicator {
  background: #2b4164;
  border-radius: 30px;
}

.reference-onboarding .select-indicator {
  background-color: #1ac0ea;
}

.reference-onboarding .title-onboarding {
  font-family: 'Montserrat', sans-serif;
  line-height: 136%;
  width: 95%;
  margin: 0 auto;
  color: var(--main-color);
}

.reference-onboarding .subtitle-onboarding {
  font-family: 'Montserrat', sans-serif;
  line-height: 136%;
  opacity: 0.6;
  margin: 16px 0;
  color: var(--main-color);
}

.reference-onboarding .option-play {
  padding: 20px 95px 20px 24px;
  gap: 12px;
  background: rgba(255, 255, 255, 0.02);
  box-shadow: inset 0px 0px 9.85714px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(13.1429px);
  border-radius: 16px;
  margin: 18px 0px;
  width: 100%;
}

.reference-onboarding input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  border: 1px solid #f3f3f34d;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  outline: none;
}
.reference-onboarding .wrapperCheck .reference-check {
  border-image: linear-gradient(90deg, #1ac0ea, #076ae3) 1 !important;
  border-width: 5px !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #1ac0ea, #076ae3) border-box !important;
  border: 4px solid transparent;
}
.reference-onboarding .check_seleccionado {
  border-image: linear-gradient(90deg, #1ac0ea, #076ae3) 1 !important;
  border-width: 5px !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #1ac0ea, #076ae3) border-box !important;
  border: 4px solid transparent;
}

.reference-onboarding .play-wrapper div svg.label_seleccionado {
  background: rgba(26, 192, 234, 0.05);
  box-shadow: inset 0px 0px 24px rgba(26, 192, 234, 0.5);
  backdrop-filter: blur(16px);
  border-radius: 16px;
}
.container-hours .label_seleccionado::before {
  background: linear-gradient( 103.18deg, #1ac0ea 3.29%, #0458e2 97.77% ) !important;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.container-hours .label_seleccionado::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 24px;
  top: 17px;
  width: 5px;
  height: 8px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}
.reference-onboarding .button-next-img {
  background: linear-gradient(271deg, #1ac0ea 3.29%, #0458e2 97.77%);
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  height: 48px;
  width: 48px;
  padding: 16px 15px 14px 13px;
  bottom: 20px;
  transform: translate(-57%, 29px) rotate(180deg);
}

.reference-onboarding .button-next-b {
  bottom: -20px;
  transform: translate(-57%, 11px);
}

.reference-onboarding .next-button {
  bottom: 15%;
  left: 50%;
}

.reference-onboarding .next-button .relative {
  height: 100%;
}

.reference-onboarding .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.reference-onboarding .modal {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
}

.reference-onboarding .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.reference-onboarding .modal-header h2 {
  margin: 0;
}

.reference-onboarding .modal-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.reference-onboarding .modal-onboarding .modal {
  background-color: var(--background-color);
}

.reference-onboarding .modal-overlay {
  background: rgba(0, 5, 18, 0.5);
  backdrop-filter: blur(5px);
}

.reference-onboarding .modal-onboarding .modal {
  background: var(--background-color);
  border-radius: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 60vh;
}

.reference-onboarding .option-play {
  padding: 16px 95px 16px 24px;
}

.reference-onboarding .seleccionado {
  background: linear-gradient(
    103.18deg,
    #1ac0ea 3.29%,
    #0458e2 97.77%
  ) !important;
  border: none !important;
}

.reference-onboarding .seleccionado {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.reference-onboarding .seleccionado::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 7px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
} 

.reference-onboarding .row-table-days {
  padding: 8px 20px;
}

.reference-onboarding .row-table-days span {
  text-align: start;
}

.reference-onboarding .input-table {
  justify-content: space-evenly;
}

.reference-onboarding .table-row-blue {
  background: linear-gradient(
    90.24deg,
    rgba(7, 106, 227, 0.42) -13.65%,
    rgba(6, 27, 46, 0) 101.54%
  );
  border-radius: 8px;
}

.reference-onboarding .input-table .seleccionado::after {
  left: 6px;
  top: 3px;
}
.reference-onboarding .wrapper-scroll-js h1 {
  position: relative;
  top: -60px;
}
</style>