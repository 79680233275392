<template>
  <div class="profileContainer text-sm mt-8">
    <div>
      <div class="topProfile flex justify-center items-center flex-col">
        <ProfileImage />
        {{ email }}
        <ProfileName />
        <ProfileNickName />
        <ProfileGender />

        <div class="w-64">
          <SportSelector v-model="sport" />
        </div>
      </div>
      <div class="bodyProfile p-6 pt-12">
        <div class="text-center mt-2 mb-2 font-bold">
          {{ $t("level") }}
        </div>
        <div v-if="mounted"
          class="progress-container flex flex-row sm:flex-col"
          :class="{ 'single-bar': !showRedpadelBar }"
        >
          <div v-if="showPandoraLevelBar" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 items-center br16 w-full">
            <p v-if="mounted" class="mb-4 font-bold">
              {{ "Taykus: " + pandoraLevel }}
            </p>
            <ProgressBar
              :firstValue="MIN_LEVEL"
              :lastValue="sport === Sport.SQUASH ? MAX_SQUASH_LEVEL : MAX_LEVEL"
              :value="pandoraLevel"
            />
          </div>
          <div v-if="!showPandoraLevelBar" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 w-full items-center br16">
            <p class="mb-4 font-bold">
              {{ $t("still_no_level_in_taykus") }}
            </p>
            <div
            @click="this.showGenderLevel = true" class="cursor-pointer">
              <p class="link">{{ $t("calculate_my_level") }}</p>
            </div>
          </div>
          <div v-if="showRedpadelBar" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 w-full items-center br16">
            <p v-if="mounted" class="mb-4 font-bold">
              {{ "World Padel Rating: " + redPadelLevel }}
            </p>
            <ProgressBar
              :firstValue="MIN_LEVEL"
              :lastValue="MAX_REDPADEL_LEVEL"
              :value="redPadelLevel"
              :showAllLevels="false"
              :showMidLevel="true"
            />
          </div>
          <div v-if="!showRedpadelBar && sport==Sport.PADEL" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 w-full items-center br16">
            <p class="mb-4 font-bold">
              {{ $t("still_no_level_in_redpadel") }}
            </p>
            <div
            @click="sendRedpadelLeveling" class="cursor-pointer">
              <p class="link">{{ $t("calculate_my_level") }}</p>
            </div>
          </div>
        </div>
        <WinRate v-if="mounted" :results="results" />
      </div>
      <Transition>
      <PopUpLight
        v-if="showGenderLevel"
        @closed="closeGenderLevel()"
        :showClose="false"
      >
        <UpdateUser
          :customSport="sport"
          @closeGenderLevel="closeGenderLevel()"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showHasGender"
        :showClose="true"
        @closed="initRedPadelLevel"
      >
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveGenderChanges" />
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showIframe" 
        @closed="closeRedpadelIframe"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          <iframe class="iframe" title="redPadel login" frameborder="0" :src="iframeSrc"></iframe>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showRedpadelError" 
        @closed="showRedpadelError= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          {{ $t("oops_technical_problems_try_again_in_a_few_minutes") }}
        </div>
      </PopUpLight>
    </Transition>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ProfileImage from "@/components/myProfile/profileImage.vue";
import ProfileName from "@/components/myProfile/profileName.vue";
import ProfileNickName from "@/components/myProfile/profileNickName.vue";
import SportSelector from "@/components/form/sportSelector.vue";
import { Sport, MIN_LEVEL, MAX_LEVEL, MAX_REDPADEL_LEVEL, LevelProvider, NO_LEVEL, MAX_SQUASH_LEVEL } from "@/enum/constants";
import ProgressBar from "@/components/form/progressBar.vue";
import WinRate from "@/components/myProfile/winRate.vue";
import CustomerHelper from "@/helpers/customerHelper";
import MatchHelper from "@/helpers/matchtHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import ProfileGender from "@/components/myProfile/profileGender.vue";
import PopUpLight from "@/components/popup/popupLight.vue";
import UpdateUser from "@/components/match/updateUser.vue";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";
import router from "@/router";

export default defineComponent({
  name: "UserProfile",
  components: {
    ProfileImage,
    ProfileName,
    ProfileNickName,
    SportSelector,
    ProgressBar,
    WinRate,
    ProfileGender,
    PopUpLight,
    UpdateUser,
    UserGenderSelector
  },
  async mounted() {
    if (window.innerWidth > 768) {
      document.body.style.background =
        "url(/img/background-test.485cbfa3.png) top 100% center";
      document.body.style.backgroundRepeat = "no-repeat";
    }

    this.getResults();
    this.fixIphoneHeight();
    if (CustomerHelper.isUserLogged()) {
      this.customerId = await CustomerHelper.getCustomer().id;
      const user = await CustomerHelper.getUser();
      this.email = user.username;
    }
  },
  
  watch: {
    sport() {
      this.updateLevels();
    },
  },
  data() {
    return {
      results: null,
      sport: Sport.PADEL,
      sportsArray: [],
      mounted: false,
      customerId: "",
      email: "",
      MIN_LEVEL:MIN_LEVEL,
      MAX_LEVEL:MAX_LEVEL,
      showRedpadelBar: false,
      showPandoraLevelBar: false,
      MAX_REDPADEL_LEVEL:MAX_REDPADEL_LEVEL,
      MAX_SQUASH_LEVEL:MAX_SQUASH_LEVEL,
      pandoraLevel: null,
      redPadelLevel: null,
      Sport:Sport,
      showHasGender: false,
      showRedpadelError: false,
      showGenderLevel: false,
      iframeSrc:"",
      showIframe: false,
      socialRating: 0,
    };
  },
  methods: {
    async getResults() {
      this.sportsArray = await CustomerHelper.getCustomerResults();
      this.updateLevels();
      this.mounted = true;
    },
    closeRedpadelIframe(){
      this.showIframe = false;
      window.removeEventListener("message", this.redPadelListener);
    },
    updateLevels(){
      this.pandoraLevel = MatchHelper.getLevelBySportAndProvider(this.sportsArray, this.sport, LevelProvider.PANDORA);
      this.redPadelLevel = MatchHelper.getLevelBySportAndProvider(this.sportsArray, this.sport, LevelProvider.REDPADEL);
      this.results = this.sportsArray.find(
        (sport) => sport.sport.code === this.sport
      );
      this.showPandoraLevelBar = this.pandoraLevel > NO_LEVEL ? this.showPandoraLevelBar = true : this.showPandoraLevelBar = false;
      this.showRedpadelBar = this.redPadelLevel > NO_LEVEL ? this.showRedpadelBar = true : this.showRedpadelBar = false;
      
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".profileContainer ");
        if (resultsContainer) {
          resultsContainer.classList.add("pb-100");
        }
      }
    },
    async closeGenderLevel() {
      this.showGenderLevel = false;
      this.getResults();
    },
    async linkRedPadelAccount(linkLevelProvider){
      try {
        await CustomerHelper.linkLevelProviderAccount(linkLevelProvider, LevelProvider.REDPADEL);
        this.getResults();
      } catch (error) {
        this.showRedpadelError = true;
      }
    },
    async initRedPadelLevel(){
      this.showHasGender = false;
      if(this.hasGender){
        const response = await CustomerHelper.getProviderRedirectURL(LevelProvider.REDPADEL);
        if(response){
          this.iframeSrc = response.redirectURL;
          this.showIframe = true;
          window.addEventListener("message", this.redPadelListener);
        }
      }
    },
    async sendRedpadelLeveling(){
      this.hasGender = await CustomerHelper.hasGender();
      if(!this.hasGender){
        this.showHasGender = true;
        return;
      }
      this.initRedPadelLevel();
    },
    async redPadelListener(event) {
      window.removeEventListener("message", this.redPadelListener);
      const resultData = event.data;
      this.showIframe = false;
      if(resultData.status){
        if(resultData.socialRating){
          this.socialRating = Number(resultData.socialRating);
        }
        const linkLevelProvider = {
          accountId: resultData.user_id,
        };
        await this.linkRedPadelAccount(linkLevelProvider);
      }else{
        this.showRedpadelError = true;
      }
    },
    async saveGenderChanges(gender) {
      const user = await CustomerHelper.updateGender(gender);
      if (user) {
        CustomerHelper.updateStorageUser(user);
        this.hasGender = true;
        this.initRedPadelLevel();
      }
      this.showHasGender = false;
    },
  },
});
</script>

<style scoped>
.link{
  color: #0458e2;
}
.progress-container {
  display: flex;
  gap: 1rem;
}

.progress-bar-wrapper {
  flex: 1; /* progress bars share space */
}

.single-bar .progress-bar-wrapper {
  flex-basis: 100%; /*only 1 bar 100% of width */
}
@media (min-width: 767px) {
  .profileContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
}
.profileContainer .m-center {
  margin: 0 auto;
}
.profileContainer .backButton {
  top: 10px;
  left: 20px;
}
.profileContainer .br100 {
  border-radius: 50%;
}
.profileContainer .topProfile {
  position: relative;
  top: 30px;
}
.profileContainer .gradiend {
  background: linear-gradient(
    103.18deg,
    var(--color1) 20.29%,
    var(--color2) 97.77%
  );
}
.MyClubs .ClubElements {
  border: 1px solid rgba(255, 255, 255, 0.089);
}

.MyClubs .opacityClubs {
  opacity: 0.7;
}
</style>